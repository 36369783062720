import TagManager from 'react-gtm-module';
import { AccountViewType } from 'contracts/window';
import { globalConfig } from 'configs/globalConfig';
import { getProduct } from 'configs/sites';
import is from 'utils/is';
import shallowEqual from 'utils/shallowEqual';
import { convertKeyToSnakeCase } from './utils';
import { DefineTracking, TrackingArgument } from './types';

export interface Parameters {
    userId?: number;
    userAccountType?: AccountViewType | null;
    viewType: 'model' | 'studio';
    language: string;
    limitedAccess: boolean;
    testAccount: boolean;
    multiplePerformers: boolean;
    authenticated: boolean;
}

interface GAConfig extends Parameters {
    application: 'MSC Portal';
    trafficType: Environments;
}

enum Environments {
    dev = 'development',
    staging = 'staging',
    prod = 'production',
}

class GoogleAnalytics {
    initialized = false;

    customParameters: Parameters = {} as Parameters;

    private gTag(...args: unknown[]): void {
        if (!window.dataLayer) {
            window.dataLayer = window.dataLayer || [];
        }

        window.dataLayer.push(args);
    }

    public init({
        viewType,
        userAccountType,
        language,
        limitedAccess,
        testAccount,
        multiplePerformers,
        authenticated,
    }: Parameters): void {
        if (this.initialized) return;

        this.initialized = true;

        this.customParameters = {
            viewType,
            language,
            limitedAccess,
            testAccount,
            multiplePerformers,
            userAccountType: userAccountType || AccountViewType.MODEL,
            authenticated,
        };

        this.gTag('config', globalConfig.gtm, {
            app_name: getProduct(),
            transport_type: 'beacon',
            send_page_view: false,
            user_properties: convertKeyToSnakeCase({
                application: 'MSC Portal',
                trafficType: Environments?.[globalConfig.applicationEnvironment] || Environments.dev,
                ...this.customParameters,
            } satisfies GAConfig),
        });

        this.gTag('event', 'page_view', {
            page_title: window.document.title,
            page_path: window.location.pathname.replace(new RegExp(`^/${language}/?`, 'i'), '/'),
            page_location: window.location.href,
            send_to: globalConfig.gtm,
        });
    }

    public persist(parameters: Partial<Parameters>) {
        if (!this.initialized || shallowEqual(parameters, this.customParameters)) return;

        const { userId, ...properties } = parameters;

        if (userId) {
            this.gTag('set', { user_id: String(userId) });
        }

        this.gTag('set', 'user_properties', convertKeyToSnakeCase({ ...this.customParameters, ...properties }));
    }

    // Sends events to Google Tag Manager
    public sendEvent<T extends TrackingArgument>(
        tracking: DefineTracking<T>[keyof DefineTracking<T>],
        values?: Record<string, string>,
    ): void {
        if (!tracking) return;

        const { action, description, category, label, nonInteraction, customProperties } =
            tracking?.format?.(values) ?? tracking;

        let event = {
            event: 'GA - Event - Generic Event',
            eventCategory: category,
            eventLabel: label,
            eventAction: is.string(action) ? action : 'click',
        };

        if (!description?.trim()) {
            throw Error(
                `[Google Analytics] Missing "description" for tracking:
        - category: ${category}
        - label: ${label}
        - action: ${action}`,
            );
        }

        if (is.bool(nonInteraction)) {
            event = { ...event, ...{ eventInteraction: !nonInteraction } };
        }

        if (customProperties) {
            event = { ...event, ...customProperties };
        }

        TagManager.dataLayer({ dataLayer: event });
    }
}

export default new GoogleAnalytics();
export { GoogleAnalytics };
