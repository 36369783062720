import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SummerContestStatItem } from 'domains/SummerContest/SummerContest.types';
import { SummerContestState } from './SummerContest.types';

export const initialState: SummerContestState = {
    getSummerContestStats: {
        requestStatus: 'initial',
    },
};

const summerContestSlice = createSlice({
    name: 'summerContest',
    initialState,
    reducers: {
        getSummerContestStatsStarted: (state) => {
            state.getSummerContestStats.requestStatus = 'pending';
        },

        getSummerContestStatsSucceeded: (state, action: PayloadAction<SummerContestStatItem[]>) => {
            state.getSummerContestStats.data = action.payload;
            state.getSummerContestStats.requestStatus = 'resolved';
        },
        getSummerContestStatsFailed: (state) => {
            state.getSummerContestStats.data = undefined;
            state.getSummerContestStats.requestStatus = 'rejected';
        },
    },
});

export const { getSummerContestStatsStarted, getSummerContestStatsSucceeded, getSummerContestStatsFailed } =
    summerContestSlice.actions;

export default summerContestSlice.reducer;
