/* eslint-disable no-restricted-imports */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageTemplateEntity } from 'domains/Messenger/MessengerTemplates/MessengerTemplates.types';
import { MessengerTemplatesState } from './MessengerTemplates.types';

export const initialState: MessengerTemplatesState = {
    messageTemplates: [],
    requestStatus: 'initial',
};

const messengerTemplatesSlice = createSlice({
    name: 'messengerTemplates',
    initialState,
    reducers: {
        fetchMessageTemplatesStarted: (state) => {
            state.requestStatus = 'pending';
        },
        fetchMessageTemplatesSucceeded: (state, action: PayloadAction<MessageTemplateEntity[]>) => {
            state.requestStatus = 'resolved';
            state.messageTemplates = action.payload;
        },
        fetchMessageTemplatesFailed: (state) => {
            state.requestStatus = 'rejected';
            state.messageTemplates = [];
        },
        createMessageTemplateSucceeded: (state, action: PayloadAction<MessageTemplateEntity>) => {
            state.messageTemplates.unshift(action.payload);
        },
        createMessageTemplateFailed: (state) => {
            state.requestStatus = 'rejected';
        },
        updateMessageTemplateSucceeded: (state, action: PayloadAction<MessageTemplateEntity>) => {
            const updatedTemplateIndex = state.messageTemplates.findIndex(
                (template) => template.id === action.payload.id,
            );
            state.messageTemplates[updatedTemplateIndex] = action.payload;
        },
        updateMessageTemplateFailed: (state) => {
            state.requestStatus = 'rejected';
        },
        deleteMessengerTemplatesStarted: (state) => {
            state.requestStatus = 'pending';
        },
        deleteMessengerTemplatesSucceeded: (state, action: PayloadAction<{ id: number }>) => {
            const { id } = action.payload;
            state.requestStatus = 'resolved';
            state.messageTemplates = state.messageTemplates.filter((item: MessageTemplateEntity) => item.id !== id);
        },
        deleteMessengerTemplatesFailed: (state) => {
            state.requestStatus = 'rejected';
        },
    },
});

export const {
    fetchMessageTemplatesStarted,
    fetchMessageTemplatesSucceeded,
    fetchMessageTemplatesFailed,
    createMessageTemplateSucceeded,
    createMessageTemplateFailed,
    updateMessageTemplateSucceeded,
    updateMessageTemplateFailed,
    deleteMessengerTemplatesStarted,
    deleteMessengerTemplatesSucceeded,
    deleteMessengerTemplatesFailed,
} = messengerTemplatesSlice.actions;
export default messengerTemplatesSlice.reducer;
